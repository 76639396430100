$(".popup-inline").magnificPopup({
    type: "inline",
    callbacks: {
        open: function () {
            var $contentContainer = $(this)[0].contentContainer;
            $contentContainer.addClass("popup-custom-iframe");
        }
    }
});
$(".video-popup, .iframe-popup").magnificPopup({
    type: "iframe",
    iframe: {
        patterns: {
            azure: {
                index: "azure.com",
                id: function () {
                    var id = 0;
                    return id;
                },
                src: "https://www.azure.com/embed/%id%"
            }
        }
    }
});
if (window.matchMedia('(max-width: 767px)').matches) {
    $(".gallery-popup a").magnificPopup({
        type: "image",
        mainClass: "image-carousel-popup",
        zoom: {
            enabled: true,
            duration: 300
        },
        gallery: {
            enabled: true,
            tCounter: "%curr% / %total%"
        },
        callbacks: {
            open: function () { // When you open the
                $('html').css('overflow', 'hidden'); // window, the element
            }, // "body" is used "overflow: hidden".
            close: function () { // When the window
                $('html').css('overflow', ''); // is closed, the 
            },
            beforeClose: function () {
                $(".image-pager li", ".component-image-carousel")
                    .eq(this.index)
                    .trigger("click");
            }           
        }
    });
}
'use strict';
/* global VideoPlayer */
/**
* JW Player support for magnific popup
*/
// Create a contained object instead of global variables
var currentJwplayer = function () {
    var self = {
        setCurrentPlayer: function setCurrentPlayer(player) {
            this.player = player;
        },
        setCurrentPlayerElement: function setCurrentPlayerElement(playerElement) {
            this.playerElement = playerElement;
        },
        getCurrentPlayer: function getCurrentPlayer() {
            return this.player;
        },
        getCurrentPlayerElement: function getCurrentPlayerElement() {
            return this.playerElement;
        }
    };
    return {
        setCurrentPlayer: self.setCurrentPlayer,
        setCurrentPlayerElement: self.setCurrentPlayerElement,
        getCurrentPlayer: self.getCurrentPlayer,
        getCurrentPlayerElement: self.getCurrentPlayerElement
    };
}();
$.magnificPopup.registerModule('jwplayer', {
    options: {
        markup: '<div class="mfp-jw-wrapper">' + '<button type="button" class="mfp-close">×</button>' + '<div id="mfp-jwplayer"></div>' + '</div>'
    },
    proto: {
        initJwplayer: function initJwplayer() { },
        setupClonedButton: function setupClonedButton(element) {
            currentJwplayer.setCurrentPlayerElement(element);
            // Create a dupe since this gets removed by the Platform Video Player
            var buttonClone = currentJwplayer.getCurrentPlayerElement().cloneNode(true);
            var parentToAddTo = currentJwplayer.getCurrentPlayerElement().parentNode;
            // Put the button copy where the removed button was
            parentToAddTo.appendChild(buttonClone);
            // Set new cloned button to be the player element
            currentJwplayer.setCurrentPlayerElement(buttonClone);
        },
        instantiatePlayer: function instantiatePlayer() {
            var urlHref = currentJwplayer.getCurrentPlayerElement().getAttribute('href');
            var urlHls = currentJwplayer.getCurrentPlayerElement().getAttribute('data-video-url-hls');
            var videoName = currentJwplayer.getCurrentPlayerElement().getAttribute('data-video-name');
            if (!(urlHref || urlHls || videoName)) {
                return;
            }
            var player = new VideoPlayer(currentJwplayer.getCurrentPlayerElement(), {
                url: {
                    progressive: urlHref,
                    hls: urlHls
                },
                analytics: {
                    name: videoName
                }
            });
            currentJwplayer.setCurrentPlayer(player);
            // Finish and play video
            setTimeout(function () {
                player.jwplayer.play();
            }, 500);
        },
        appendPlayerToPopup: function appendPlayerToPopup() {
            if (!currentJwplayer.getCurrentPlayer()) {
                return;
            }
            waitForJwplayerContainer('#mfp-jwplayer', 100);
        },
        getJwplayer: function getJwplayer(item, template) {
            this.updateStatus('ready');
            var isDone = false;
            this.ev.on('mfpOpen', function () {
                // Setup video and make sure it only does it once
                $('.ProductDetails-backgroundColor').addClass('mfp-zoom-out-cur');
                if (!isDone) {
                    var clickedElement = item.el[0];
                    $.magnificPopup.proto.setupClonedButton(clickedElement);
                    $.magnificPopup.proto.instantiatePlayer();
                    $.magnificPopup.proto.appendPlayerToPopup();
                    if (!currentJwplayer.getCurrentPlayer()) {
                        return;
                    }
                    isDone = true;
                }
            });
            this.ev.on('mfpChange', function () {
                if (!isDone) {
                    var clickedElement = item.el[0];
                    $.magnificPopup.proto.setupClonedButton(clickedElement);
                    $.magnificPopup.proto.instantiatePlayer();
                    $.magnificPopup.proto.appendPlayerToPopup();
                    if (!currentJwplayer.getCurrentPlayer()) {
                        return;
                    }
                    isDone = true;
                }
            });
            return template;
        }
    }
});

function waitForJwplayerContainer(selector, time) {
    if (document.querySelector(selector) !== null) {
        var playerElement = document.querySelector('#' + currentJwplayer.getCurrentPlayer().element.id);
        if (document.querySelector('#mfp-jwplayer').hasChildNodes()) {
            document.querySelector('#mfp-jwplayer').removeChild(document.querySelector('#mfp-jwplayer').firstChild);
        }
        document.querySelector('#mfp-jwplayer').appendChild(playerElement)
        return;
    } else {
        setTimeout(function () {
            waitForJwplayerContainer(selector, time);
        }, time);
    }
}

function uglyJwplayerWrapperHack() {
    var videoObj = $('.jw-video-popup');
    var jwChildWithPop = videoObj.find($('.jw-video-popup'));
    if (jwChildWithPop.length > 0) {
        jwChildWithPop.removeClass('jw-video-popup');
    }
}
uglyJwplayerWrapperHack();
// Add to jQuery (oe) prototype â€“Â without this the popup will not display, instead just going to a fullscreen video (normal, but unwanted behavior)
// If the class here is changed, the modal will not work
$('.jw-video-popup').magnificPopup({
    type: 'jwplayer'
}); 